import { useState, useEffect } from 'react'
import { Picture, Text } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import { cn, rmEmptyKeys } from '@components/helper'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import Button from '@components/control/Button'

import isMobile from 'ismobilejs'
import s from './index.module.css'
import ProgressBar from '../ProgressBar'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const Index = ({ id, data, pageHandle, abtest }) => {
  const [mobile, setMobile] = useState(false)
  const [swiperRef, setSwiperRef] = useState(null)
  const [realIndex, setRealIndex] = useState(0)

  useEffect(() => {
    if (isMobile(window.navigator).phone) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }, [])

  const settings = {
    speed: 400,
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 16,
    slideToClickedSlide: true,
    autoplay: data?.autoplay || false,
    modules: [Navigation, Autoplay, Pagination],
    pagination:
      data?.list?.length > 2 || mobile
        ? {
            clickable: true,
            bulletActiveClass: s.activeBullet,
            bulletClass: s.bullet,
          }
        : false,
    breakpoints: {
      0: {
        spaceBetween: 12,
        slidesPerView: 1.1,
      },
      768: {
        spaceBetween: 12,
        slidesPerView: 1.1,
      },
      1023: {
        spaceBetween: 12,
        slidesPerView: 2,
      },
    },
  }

  return (
    <section
      id={id}
      className={cn(
        'layer relative overflow-hidden bg-[#F8F9FA] py-[140px] leading-[1.3] md:py-[65px] md:pb-[90px] md:pr-0',
        {
          ['pt-0 md:pt-0']: data?.delPaddingTop,
          ['pb-0 md:pb-0']: data?.delPaddingBottom,
        }
      )}
      style={rmEmptyKeys({ background: data?.bgColor })}
    >
      <div className="content !max-w-[1410px]">
        {data?.title && (
          <Text
            html={data.title}
            className="mb-[50px] text-[56px] font-[700] uppercase text-[#000] md:mb-[28px] md:text-[32px]"
          />
        )}

        <Swiper
          {...settings}
          className={s.swiperBox}
          onSwiper={setSwiperRef}
          onSlideChange={(swiper) => setRealIndex(swiper.realIndex)}
        >
          {data?.list?.map((item, index) => (
            <SwiperSlide key={index} className={s.swiperSlide}>
              <Row
                item={item}
                data={data}
                key={index}
                index={index}
                pageHandle={pageHandle}
                abtest={abtest}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <style jsx global>{`
        #${id} .swiper-button-prev,
        #${id} .swiper-button-next {
          width: 18px;
          opacity: 0;
          height: 33px;
          color: #fff;
          transition: all 200ms ease-out;
        }

        #${id} .swiper-button-prev {
          left: 40px;
        }

        #${id} .swiper-button-next {
          right: 40px;
        }

        #${id} .${s.swiperBox}:hover .swiper-button-prev,
        #${id} .${s.swiperBox}:hover .swiper-button-next {
          opacity: 1;
        }

        #${id} .${s.swiperBox}:hover .swiper-button-disabled {
          opacity: 0.35;
        }

        #${id} .swiper-button-next:after,
        #${id} .swiper-rtl .swiper-button-prev:after,
        #${id} .swiper-button-prev:after,
        #${id} .swiper-rtl .swiper-button-next:after {
          font-size: 33px;
        }

        #${id} .${s.swiperBox} .swiper-pagination {
          bottom: -40px;
          display: flex;
          gap: 5px;
          justify-content: center;
        }

        @media (max-width: 769px) {
          #${id} .swiper-button-next,
          #${id} .swiper-button-prev {
            display: none;
          }

          #${id} .${s.swiperBox} .swiper-pagination {
            bottom: -30px;
          }
        }
      `}</style>
    </section>
  )
}

export default Index

const Row = ({ item, data, pageHandle, index, abtest }) => {
  const { setRelativeLink } = useRelativeLink()

  let link = `${setRelativeLink({ link: item?.link })}?ref=${
    data?.ref ? data?.ref + (index + 1) : pageHandle
  }`

  return (
    <div className={s.hoverBox}>
      <Picture
        source={`${item?.img}, ${item?.mobImg || item?.img} 768`}
        className={cn(
          'aspect-h-[480] aspect-w-[697] w-full md:aspect-h-[200] md:aspect-w-[335] md:h-auto'
        )}
      />

      <div className={s.absoluteBox}>
        <div
          className={cn(s.textWrap, {
            [s.textWrapAbtest]: abtest,
          })}
        >
          <Text
            html={item.title}
            className="text-[24px] font-[700] uppercase text-[#fff] md:mt-[20px] md:text-[20px] md:text-[#000]"
          />
          <div
            className={cn(s.arrowWrap, {
              [s.arrowWrapAbtest]: abtest,
            })}
          >
            <Text
              html={item.desc}
              className={s.colDesc}
              style={rmEmptyKeys({ color: data.descColor })}
            />

            {!abtest ? (
              <>
                <a
                  className={s.learnMore}
                  href={link}
                  dangerouslySetInnerHTML={{ __html: item?.learnMore }}
                />
              </>
            ) : (
              <>
                {item?.link && (
                  <a href={link} className={s.learnMore}>
                    <Text html={item?.learnMore} />
                    <ChevronRightIcon className="h-[18px] w-[18px]" />
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <ProgressBar s={{ progressBarBox: s.progressBarBox }} />
    </div>
  )
}
