import usePrice, { formatPrice } from '@commerce/product/use-price'
import { useRouter } from 'next/router'
import { cn } from '@components/helper'

const Price = ({
  product,
  copy,
  priceStyle = {},
  className = '',
  s = {},
  soldOutTxt = '',
  showPrice = false,
  totalDiscountPrice = null,
  totalOriginalPrice = null,
  customPrice = null,
  customOriginalPrice = null,
  hideBasePrice = false,
}) => {
  const { locale } = useRouter()

  const basePrice = usePrice({
    amount: Number(product?.basePrice || 0),
    currencyCode: product?.currencyCode || 'USD',
  })

  // console.log('basePrice:', basePrice)
  // console.log(
  //   'product.basePrice===prduct.price:',
  //   product.basePrice === product.price
  // )

  return (
    <div className={cn(s.priceWrap, className, 'DealsPriceBox')}>
      {product?.availableForSale || showPrice ? (
        <>
          <span
            style={priceStyle}
            className={cn(
              'DealsPrice pr-2 text-[20px] font-semibold md:text-[14px]',
              s.price
            )}
          >
            {product?.fakePrice
              ? product?.fakePrice
              : totalDiscountPrice && !customPrice
              ? totalDiscountPrice
              : formatPrice({
                  amount: Number(customPrice || product?.price || 0),
                  currencyCode: product?.currencyCode || 'USD',
                  locale,
                })}
          </span>
          {totalOriginalPrice &&
            totalOriginalPrice !== totalDiscountPrice &&
            !customOriginalPrice &&
            !hideBasePrice && (
              <span
                className={cn(
                  'DealsBasePrice text-[#999] line-through md:text-[12px]',
                  s.basePrice
                )}
              >
                {totalOriginalPrice}
              </span>
            )}
          {!totalOriginalPrice &&
          !customOriginalPrice &&
          !hideBasePrice &&
          product?.basePrice &&
          product?.basePrice > product?.price ? (
            <span
              className={cn(
                'DealsBasePrice text-[#999] line-through md:text-[12px]',
                s.basePrice
              )}
            >
              {basePrice.price}
            </span>
          ) : null}
          {customOriginalPrice && !hideBasePrice && (
            <span
              className={cn(
                'DealsBasePrice text-[#999] line-through md:text-[12px]',
                s.basePrice
              )}
            >
              {formatPrice({
                amount: Number(customOriginalPrice || 0),
                currencyCode: product?.currencyCode || 'USD',
                locale,
              })}
            </span>
          )}
        </>
      ) : (
        <span
          className={cn(
            'pr-2 text-[20px] font-semibold md:text-[14px]',
            s.soldOut
          )}
        >
          {soldOutTxt || copy?.soldOut}
        </span>
      )}
    </div>
  )
}

export default Price
