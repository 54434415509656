import { Picture, Text } from '@components/ui'
import { cn, rmEmptyKeys } from '@components/helper'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import s from './index.module.css'
import ProgressBar from '../ProgressBar'
import Button from '@components/control/Button'
import SolixButton from '@components/control/SolixButton'

import { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useTimer } from 'react-timer-hook'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// start_ai_generated
const PrevArrow = ({ onClick, active, c = '', data }) => (
  <button
    onClick={onClick}
    className={cn(c, s.arrow, s.arrowLeft, 'hesSb_arrow-left -translate-y-1/2')}
  >
    <Picture
      className="h-[32px] w-[32px]"
      source={
        data?.arrowImg ||
        `https://cdn.shopify.com/s/files/1/0493/9834/9974/files/Kit-Arrows.svg?v=1724056204`
      }
    />
  </button>
)
// end_ai_generated

// start_ai_generated
const NextArrow = ({ onClick, active, c = '', data }) => (
  <button
    onClick={onClick}
    className={cn(
      c,
      s.arrow,
      s.arrowRight,
      'hesSb_arrow-right -translate-y-1/2 rotate-180'
    )}
  >
    <Picture
      className="h-[32px] w-[32px]"
      source={
        data?.arrowImg ||
        `https://cdn.shopify.com/s/files/1/0493/9834/9974/files/Kit-Arrows.svg?v=1724056204`
      }
    />
  </button>
)
// end_ai_generated

const CountDown = ({ data }) => {
  if (!data || !data.endTime || new Date() > new Date(data.endTime)) return null

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(data.endTime),
  })
  const themeEnums = {
    undefined: 'bg-[#000] text-[#FFF]',
    primary: 'bg-[#000] text-[#FFF]',
    white: 'bg-[#fff] text-[#000]',
    orage: 'bg-[#FF7815] text-[#FFF]',
    blue: 'bg-[#00A9E0] text-[#FFF]',
  }
  const themeCName = themeEnums[data?.theme]
  return (
    <div className="mt-6 flex justify-start md:justify-center">
      {!data.hideDays && !data.countdown24 && days > 0 && (
        <Item time={days} copy={data.days} themeCName={themeCName} />
      )}
      <Item
        time={
          data.countdown24 || !data.hideDays
            ? (days * 24 + hours) % 24
            : days * 24 + hours
        }
        copy={data.hours}
        theme={data.theme}
        themeCName={themeCName}
      />
      <Item
        time={minutes}
        copy={data.minutes}
        theme={data.theme}
        themeCName={themeCName}
      />
      <Item
        time={seconds}
        copy={data.seconds}
        last={true}
        themeCName={themeCName}
      />
    </div>
  )
}

const Item = ({ time, copy, last = false, themeCName }) => (
  <div className="flex flex-col items-center">
    <div className="flex items-center">
      <div
        suppressHydrationWarning={true}
        className={cn(
          'box-border flex h-[60px] w-[60px] flex-col items-center justify-center pt-[5px] l:h-[46px] l:w-[46px]',
          themeCName
        )}
      >
        <div className="text-[36px] font-[600] leading-[0.9] l:text-[28px]">
          {time < 10 ? `0${time}` : time}
        </div>
        <div className="text-[10px] font-[500] uppercase"> {copy}</div>
      </div>
      {!last && (
        <span
          className={
            'px-[10px] text-[32px] font-[600] text-[#6E6E73] l:text-[30px]'
          }
        >
          :
        </span>
      )}
    </div>
  </div>
)

const Index = ({ pageHandle, data, id, abtest }) => {
  const [hasOffsetWidth, setHasOffsetWidth] = useState(true)
  const [swiperRef, setSwiperRef] = useState(null)
  const HesSwiperBannerRef = useRef()

  const resizeWindow = () => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0

    const availableWidth =
      offsetWidth - (offsetWidth > 1023 ? 160 : offsetWidth > 767 ? 40 : 24) // 假设间距为160px
    // 判断是否小于1410px
    if (availableWidth < 1410) {
      // 在这里执行你的逻辑
      setHasOffsetWidth(false)
    } else {
      setHasOffsetWidth(true)
    }
  }

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)

    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  const bgMap = {
    light: '#000',
    dark: '#ffffff',
  }

  // 当组件完全出现在页面时才开启自动
  useEffect(() => {
    if (swiperRef?.autoplay) swiperRef.autoplay.stop()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [swiperRef])

  const handleScroll = () => {
    if (
      HesSwiperBannerRef?.current?.getBoundingClientRect()?.bottom <=
      window.innerHeight
    ) {
      if (swiperRef?.autoplay && !swiperRef?.autoplay?.running)
        swiperRef.autoplay.start()
    }
  }

  return (
    <section
      id={id}
      ref={HesSwiperBannerRef}
      className="mb-[6px] leading-[1.2]"
      style={rmEmptyKeys({ background: data?.bgColor })}
    >
      <Swiper
        loop={true}
        onSwiper={setSwiperRef}
        speed={data?.speed || 400}
        autoplay={{
          delay: data?.autoplaySpeed || 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        navigation={{
          nextEl: '.hesSb_arrow-right',
          prevEl: '.hesSb_arrow-left',
        }}
        slidesPerView={'auto'}
        initialSlide={0}
        centeredSlides={true}
        className={s.swiperBox}
        breakpoints={{
          0: {
            spaceBetween: 0,
          },
          1570: {
            spaceBetween: 16,
          },
        }}
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            const theme = data?.list[index]?.theme || 'dark'
            return `<li class="${className}"><div style="background-color: ${bgMap[theme]}" class="slick-dots-progress"></div></li>`
          },
        }}
        onAutoplayTimeLeft={(swiper, timeLeft, percentage) => {
          document.querySelector(
            '.swiper-pagination-bullet-active .slick-dots-progress'
          ).style.width = `${(1 - percentage) * 100}%`
        }}
        onSlideChange={(swiper) => {
          let slickDotsProgress = document.querySelectorAll(
            '.slick-dots-progress'
          )
          slickDotsProgress.forEach((item) => {
            item.style.width = '0%'
          })
        }}
        modules={[Navigation, Pagination, Autoplay]}
      >
        {data?.list?.map((item, index) => {
          return (
            <SwiperSlide
              className={cn('relative mx-auto', {
                [s.sliderItem]: hasOffsetWidth,
                [s.sliderLessThan]: !hasOffsetWidth,
              })}
              key={index}
            >
              <Row
                data={data}
                item={item}
                index={index}
                pageHandle={pageHandle}
                abtest={abtest}
              />
            </SwiperSlide>
          )
        })}
        <PrevArrow data={data}></PrevArrow>
        <NextArrow data={data}></NextArrow>
      </Swiper>
      <style jsx global>
        {`
          #${id} .${s.swiperBox} .swiper-pagination {
            bottom: 30px;
          }
          #${id} .${s.swiperBox} li {
            width: 20px;
            height: 3px;
            background-color: #6e6e73;
            border-radius: 4px;
            transition: all 0.3s ease-out;
            overflow: hidden;
            opacity: 1;
            margin: 0;
            margin-right: 5px;
          }
          #${id} .${s.swiperBox} .swiper-pagination-bullet-active {
            width: 80px;
          }
          #${id} .${s.swiperBox} .slick-dots-progress {
            width: 0%;
            height: 100%;
          }
          #${id}
            .${s.swiperBox}
            .swiper-pagination-bullet-active
            .slick-dots-progress {
            background-color: #000000;
          }
          #${id} .${s.swiperBox} .swiper-slide.swiper-slide-active .mask {
            background: transparent;
            z-index: -1;
          }
          #${id}
            .${s.swiperBox}
            .swiper-slide.swiper-slide-active
            .${s.absoluteBox} {
            opacity: 100;
          }
          @media (max-width: 767px) {
            #${id} .${s.swiperBox} li {
              width: 16px;
            }
            #${id} .${s.swiperBox} .swiper-pagination-bullet-active {
              width: 40px;
            }
            #${id} .${s.swiperBox} .swiper-pagination {
              bottom: 20px;
            }
          }
        `}
      </style>
    </section>
  )
}

export default Index

const Row = ({ item, data, pageHandle, index, abtest }) => {
  const { setRelativeLink } = useRelativeLink()
  return (
    <div className={s.itemBox}>
      <div className={cn(s.mask, 'mask')}></div>
      <Picture
        source={`${item?.img}, ${item?.mobImg || item?.img} 768`}
        className="aspect-h-[609] aspect-w-[1430] w-full md:aspect-h-[580] md:aspect-w-[390]"
        alt={item?.alt}
      />
      <div
        className={cn(s.absoluteBox, {
          [s.light]: item?.theme == 'light',
        })}
      >
        {item?.subtitle && <Text html={item.subtitle} className="uppercase" />}
        {item?.title && <Text html={item.title} className={s.itemTitle} />}
        {item?.desc && <Text html={item.desc} />}
        {item?.countDown && <CountDown data={item.countDown} />}
        {item?.buttonList?.length && (
          <>
            <div className="mt-[48px] flex gap-[25px] md:hidden md:justify-center l:mt-[29px]">
              {!abtest ? (
                <>
                  {item?.buttonList?.map((child, childIndex) => {
                    switch (child.type) {
                      case 'buyNow':
                        return (
                          <SolixButton
                            key={childIndex}
                            className={s.solixButton}
                          >
                            <a
                              className="block text-[20px] uppercase md:text-[14px]"
                              href={`${setRelativeLink({
                                link: child?.link,
                              })}?ref=${
                                data?.ref ? data?.ref + (index + 1) : pageHandle
                              }`}
                              dangerouslySetInnerHTML={{ __html: child?.text }}
                            ></a>
                          </SolixButton>
                        )
                      default:
                        return (
                          <Button
                            key={childIndex}
                            border
                            small
                            theme={item?.theme || 'dark'}
                            className={cn(s.button, '!leading-[52px]')}
                          >
                            <a
                              className="block uppercase md:text-[14px]"
                              href={`${setRelativeLink({
                                link: child?.link,
                              })}?ref=${
                                data?.ref ? data?.ref + (index + 1) : pageHandle
                              }`}
                              dangerouslySetInnerHTML={{ __html: child?.text }}
                            ></a>
                          </Button>
                        )
                    }
                  })}
                </>
              ) : (
                <>
                  {item?.buttonList?.map((child, childIndex) => (
                    <a
                      key={childIndex}
                      href={`${setRelativeLink({ link: child?.link })}?ref=${
                        data?.ref ? data?.ref + (index + 1) : pageHandle
                      }`}
                      className={s.text}
                    >
                      <Text
                        html={child?.text}
                        style={{ color: child?.text || '' }}
                      />
                      <ChevronRightIcon
                        className="hover:[#00A9E0] h-[18px] w-[18px]"
                        style={{ color: child?.text || '' }}
                      />
                    </a>
                  ))}
                </>
              )}
            </div>
            <div className="mt-[29px] flex justify-center gap-[12px] min-md:hidden">
              {item?.buttonList?.map((child, childIndex) => {
                switch (child.type) {
                  case 'buyNow':
                    return (
                      <SolixButton key={childIndex} className={s.solixButton}>
                        <a
                          className="block text-[20px] uppercase md:text-[14px]"
                          href={`${setRelativeLink({
                            link: child?.link,
                          })}?ref=${
                            data?.ref ? data?.ref + (index + 1) : pageHandle
                          }`}
                          dangerouslySetInnerHTML={{ __html: child?.text }}
                        ></a>
                      </SolixButton>
                    )
                  default:
                    return (
                      <Button
                        key={childIndex}
                        border
                        small
                        theme={item?.theme || 'dark'}
                        className={cn(s.button, '!leading-[40px]')}
                      >
                        <a
                          className="block uppercase md:text-[14px]"
                          href={`${setRelativeLink({
                            link: child?.link,
                          })}?ref=${
                            data?.ref ? data?.ref + (index + 1) : pageHandle
                          }`}
                          dangerouslySetInnerHTML={{ __html: child?.text }}
                        ></a>
                      </Button>
                    )
                }
              })}
            </div>
          </>
        )}
      </div>

      <ProgressBar s={{ progressBarBox: s.progressBarBox }} />
    </div>
  )
}
