import { cn } from '@components/helper'
import { Picture, Text } from '@components/ui'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import Button from '@components/control/Button'
import SolixButton from '@components/control/SolixButton'
import { useTimer } from 'react-timer-hook'

import s from './index.module.css'

const CountDown = ({ data }) => {
  if (!data || !data.endTime || new Date() > new Date(data.endTime)) return null

  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp: new Date(data.endTime),
  })
  const themeEnums = {
    undefined: 'bg-[#000] text-[#FFF]',
    primary: 'bg-[#000] text-[#FFF]',
    white: 'bg-[#fff] text-[#000]',
    orage: 'bg-[#FF7815] text-[#FFF]',
    blue: 'bg-[#00A9E0] text-[#FFF]',
  }
  const themeCName = themeEnums[data?.theme]
  return (
    <div className="mt-6 flex justify-start md:justify-center l:mt-5">
      {!data.hideDays && !data.countdown24 && days > 0 && (
        <Item time={days} copy={data.days} themeCName={themeCName} />
      )}
      <Item
        time={
          data.countdown24 || !data.hideDays
            ? (days * 24 + hours) % 24
            : days * 24 + hours
        }
        copy={data.hours}
        theme={data.theme}
        themeCName={themeCName}
      />
      <Item
        time={minutes}
        copy={data.minutes}
        theme={data.theme}
        themeCName={themeCName}
      />
      <Item
        time={seconds}
        copy={data.seconds}
        last={true}
        themeCName={themeCName}
      />
    </div>
  )
}

const Item = ({ time, copy, last = false, themeCName }) => (
  <div className="flex flex-col items-center">
    <div className="flex items-center">
      <div
        suppressHydrationWarning={true}
        className={cn(
          'flex h-[60px] w-[60px] flex-col items-center justify-center pt-[5px] l:h-[46px] l:w-[46px]',
          themeCName
        )}
      >
        <div className="text-[36px] font-[600] leading-[0.9] l:text-[28px]">
          {time < 10 ? `0${time}` : time}
        </div>
        <div className="text-[10px] font-[500] uppercase"> {copy}</div>
      </div>
      {!last && (
        <span
          className={
            'px-[10px] text-[32px] font-[600] text-[#6E6E73] l:text-[30px]'
          }
        >
          :
        </span>
      )}
    </div>
  </div>
)

const Index = ({ data, id, pageHandle }) => {
  const { setRelativeLink } = useRelativeLink()

  return (
    <section className={s.hesBannerWrap} id={id}>
      <Picture
        source={`${data?.img}, ${data?.xlImg || data?.img} 1539, ${
          data?.mobImg || data?.img
        } 768`}
        className="aspect-h-[800] aspect-w-[1920] w-full md:aspect-h-[580] md:aspect-w-[390]"
        alt={data?.alt}
        loading="eager"
      />
      <div className="absolute left-0 top-0 h-full w-full">
        <div className="layer h-full">
          <div
            className={cn('content', s.contentBox, {
              [s.light]: data?.theme == 'light',
            })}
          >
            {data?.subtitle && (
              <Text html={data.subtitle} className="uppercase" />
            )}
            {data?.title && (
              <Text
                html={data.title}
                className="py-[6px] text-[56px] font-[700] uppercase l:text-[32px]"
              />
            )}
            {data?.desc && <Text html={data.desc} />}
            {data?.countDown && <CountDown data={data.countDown} />}

            <div className="mt-[37px] flex gap-[16px] md:justify-center md:gap-[12px] l:mt-5">
              {data?.buttonList?.map((item, index) => {
                switch (item.type) {
                  case 'buyNow':
                    return (
                      <SolixButton key={index} className={s.solixButton}>
                        <a
                          className="block text-[18px] uppercase md:text-[14px]"
                          href={`${setRelativeLink({ link: item?.link })}?ref=${
                            data?.ref || pageHandle
                          }`}
                          dangerouslySetInnerHTML={{ __html: item?.text }}
                        ></a>
                      </SolixButton>
                    )
                  default:
                    return (
                      <Button
                        key={index}
                        border
                        small
                        theme={data?.theme || 'dark'}
                        className={s.button}
                      >
                        <a
                          className="block  uppercase md:text-[14px]"
                          href={`${setRelativeLink({ link: item?.link })}?ref=${
                            data?.ref || pageHandle
                          }`}
                          dangerouslySetInnerHTML={{ __html: item?.text }}
                        ></a>
                      </Button>
                    )
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Index
